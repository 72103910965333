import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { MainSliderRequestModel } from '../../../shareds/components/main-slider/models/main-slider-request.model';
import { MainSliderService } from '../../../shareds/components/main-slider/services/main-slider.service';
import { MainSliderResponseModel } from '../../../shareds/components/main-slider/models/main-slider-response.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  standalone:true,
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrl: './discount.component.scss',
  imports:[TranslateModule]
})
export class DiscountComponent implements OnInit{
  isAuth = false;
  currentLang = 'tr'
  mainSliderList: MainSliderResponseModel[] = []
  
  constructor(
    public router: Router,
    private translate:TranslateService,
    private localStorageService:LocalStorageService,
    private mainSliderService: MainSliderService,
    private googleAnalyticsService: GoogleAnalyticsService,
    ) { }

  ngOnInit(): void {
    this.isAuth = JSON.parse(this.localStorageService.getItem('auth') + '');
    this.currentLang = this.localStorageService.getItem('lang') || 'tr'
    this.translate.onLangChange.subscribe((data) => {
      this.currentLang = data.lang
    });
    this.getMainSlider()
  }

  getMainSlider() {
    const payload: MainSliderRequestModel = {
      Position: 8,
      Platform: 1,
      SiteId: 1,
      IsLive: 1,
      IsTest: 1,
      LangId: 1,
      IsUserLoggedIn: true
    }

    this.mainSliderService.getMainSlide(payload).subscribe(result => {
      if (result.Result === 200 && result.Data.length) {
        this.mainSliderList = result.Data
        this.mainSliderList = this.mainSliderList.reverse()
      }
    })

  }
  googleAnalytics(url:any) {
    this.googleAnalyticsService.gtag("event", "banner_indirim", {
      value: url
    })
  }
}
