<div class="buy-for-me-container">
    <div class="banner">
        <div class="banner-text">
            <div class="banner-text-wrapper">

                @if(!isAuth) {
                <h1 style="font-size: 28px;">{{'IS_AI_RELIABLE_1' | translate}}</h1>
                <a (click)="navigateRouter('signup')" class="btn-type-1" style="margin-top: 24px;"> {{'TRY_FOR_FREE' |
                    translate}} </a>
                }
                @if(isAuth && auth?.PaidMember) {
                <h1 style="font-size: 28px;">{{'IS_AI_RELIABLE_1' | translate}}</h1>
                }
                @if(isAuth && !auth?.PaidMember) {
                <h1 style="font-size: 28px;">{{'IS_AI_RELIABLE_1_1' | translate}}</h1>
                <a (click)="navigateRouter('signup')" class="btn-type-1" style="margin-top: 24px;">
                    {{'COMPLATE_YOUR_MEMBERSHIP' |
                    translate}} </a>
                }
            </div>
        </div>
        <img src="../../../../../assets/img/is-amerikadaniste-reliable/1.webp" alt="" class="">

    </div>
    <div class="content-container">
        <div class="step-1">
            <h2>{{'IS_AI_RELIABLE_2' | translate}}</h2>
            <p>{{'IS_AI_RELIABLE_3' | translate}}</p>
        </div>

        <div class="full-width">
            <div class="full-width-wrapper">
                <div class="item-1">
                    <div class="left">
                        <h2>{{'IS_AI_RELIABLE_4' | translate}}</h2>
                        <p>{{'IS_AI_RELIABLE_5' | translate}}</p>
                    </div>
                    <div class="right">
                        <img src="../../../../../assets/img/is-amerikadaniste-reliable/2.webp" alt="">
                    </div>
                </div>
            </div>
        </div>
        

        <div class="item-1 row-reverse">
            <div class="left">
                <h2>{{'IS_AI_RELIABLE_6' | translate}}</h2>
                <p>{{'IS_AI_RELIABLE_7' | translate}}</p>
            </div>
            <div class="right">
                <img src="../../../../../assets/img/is-amerikadaniste-reliable/3.webp" alt="">
            </div>
        </div>
        <div class="full-width">
            <div class="full-width-wrapper">
        <div class="item-1">
            <div class="left">
                <h2>{{'IS_AI_RELIABLE_8' | translate}}</h2>
                <p>{{'IS_AI_RELIABLE_9' | translate}}</p>
            </div>
            <div class="right">
                <img src="../../../../../assets/img/is-amerikadaniste-reliable/4.webp" alt="">
            </div>
        </div>
            </div>
        </div>
        <div class="item-1 row-reverse">
            <div class="left">
                <h2>{{'IS_AI_RELIABLE_10' | translate}}</h2>
                <p>{{'IS_AI_RELIABLE_11' | translate}}</p>
            </div>
            <div class="right">
                <img src="../../../../../assets/img/is-amerikadaniste-reliable/5.webp" alt="">
            </div>
        </div>

        <div class="full-width">
            <div class="full-width-wrapper">
        <div class="item-1">
            <div class="left">
                <h2>{{'IS_AI_RELIABLE_12' | translate}}</h2>
                <p>{{'IS_AI_RELIABLE_13' | translate}}</p>
            </div>
            <div class="right">
                <img src="../../../../../assets/img/is-amerikadaniste-reliable/6.webp" alt="">
            </div>
        </div>
            </div>
        </div>
        @if(!isAuth) {
        <div class="full-width" style="background-color: #1C1C1C;color:#fff">
            <div class="full-width-wrapper try-for-free"
                style="flex-direction: row; justify-content: space-between;align-items: center;padding: 24px 12px;">
                <span style="max-width: 100%;width: 550px;">{{'IS_AI_RELIABLE_14' | translate}}</span>
                @if(!isAuth) {
                <a (click)="navigateRouter('signup')" class="btn-type-1"> {{'SIGN_UP_NOW' | translate}} </a>
                }
            </div>
        </div>
        }
        <div class="step-1">
            <p>{{'IS_AI_RELIABLE_16' | translate}}</p>
        </div>
        <div class="work-flow">
            <img src="../../../../../assets/img/is-amerikadaniste-reliable/ai-logo.webp" alt="" class="logo">
            <div class="item-container">
                <div class="item">
                    <img src="../../../../../assets/img/is-amerikadaniste-reliable/7-1.webp" alt="">
                    <span>{{'IS_AI_RELIABLE_23' | translate}}</span>
                </div>
                <img src="../../../../../assets/img/is-amerikadaniste-reliable/right-arrow.webp" class="arrow" />
                <div class="item">
                    <img src="../../../../../assets/img/is-amerikadaniste-reliable/7-2.webp" alt="">
                    <span>{{'IS_AI_RELIABLE_24' | translate}}</span>
                </div>
                <img src="../../../../../assets/img/is-amerikadaniste-reliable/right-arrow.webp" class="arrow" />
                <div class="item">
                    <img src="../../../../../assets/img/is-amerikadaniste-reliable/7-4.webp" alt="">
                    <span>{{'IS_AI_RELIABLE_26' | translate}}</span>
                </div>
            </div>

        </div>
        <div class="step-1" style="display: flex; flex-direction: column; align-items: center;">
            <h2>{{'IS_AI_RELIABLE_17' | translate}}</h2>
            <p>{{'IS_AI_RELIABLE_18' | translate}}</p>
            <div class="full-width"
                style="background-color: #1C1C1C;color:#fff;border-radius: 20px;max-width: 1320px;width: 96vw;margin-top: 24px;">
                <div class="full-width-wrapper try-for-free"
                    style="flex-direction: row; justify-content: space-between;align-items: center;padding: 24px 12px;">
                    <span style="max-width: 100%;width: 550px;text-align: left;">{{'IS_AI_RELIABLE_19' |
                        translate}}</span>
                    <a (click)="navigateRouter('how-it-works')" class="btn-type-1"> {{'HOW_IT_WORKS' | translate}} </a>
                </div>
            </div>
        </div>
        <br>
        <br>
        <div class="full-width banner one-month-container">
            <div class="banner-text">
                <div class="banner-text-wrapper">
                    @if(isAuth && auth?.PaidMember) {
                        <h2>{{'IS_AI_RELIABLE_27' | translate}}</h2>
                        <a (click)="navigateRouter('shopping')" class="btn-type-1 one-month" style="font-size: 14px;">
                            {{'SHOPPING' | translate}} </a>
                    }
                    @else {
                    <h2>{{'IS_AI_RELIABLE_20' | translate}}</h2>
                    <p style="margin-bottom: 48px;">{{'IS_AI_RELIABLE_21' | translate}}</p>
                    @if(!isAuth) {
                    <a (click)="navigateRouter('signup')" class="btn-type-1 one-month" style="font-size: 14px;">
                        {{'SIGN_UP_NOW' | translate}} </a>
                    }
                    }

                </div>
            </div>
            <img src="../../../../../assets/img/is-amerikadaniste-reliable/8.webp" alt="" class="one-month-img-web">
            <img src="../../../../../assets/img/is-amerikadaniste-reliable/8-1.webp" alt=""
                class="one-month-img-mobile">
        </div>

    </div>
</div>