<header class="banner">
    <img src="../../../../assets/img/landings/holiday/header.webp" alt="">
</header>

    <main>
        @if(translate.currentLang == 'en') {
            <section class="intro">
                <h1>Discover Holiday Deals from America!</h1>
                <p> Find the most special gifts of the year for your loved ones, stylish celebration outfits, elegant table decorations, and so much more. Start exploring the exclusive deals and promotions from America’s leading shopping sites and brands today!</p>
                
            </section>
           
            <h2 style="text-align: center;">Brands & Campaigns</h2>

        } @else {
        <section class="intro">
            <h1>Amerika’dan Holiday Fırsatlarını Keşfedin!</h1>
            <p>Sevdikleriniz için yılın en özel hediyeleri, şık kutlama kombinleri, zarif sofra dekorasyonları ve çok daha fazlası… Amerika’nın önde gelen alışveriş siteleri ve markalarının kampanyalarını ve fırsatlarını keşfetmek için hemen incelemeye başlayın.</p>
            
        </section>
        
            <h2 style="text-align: center;">Markalar & Kampanyalar</h2>
        
    }

        <section class="grid-section four-columns">
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/1.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/2.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/3.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/4.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/5.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/6.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/7.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/8.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/47.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/48.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/49.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/50.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/9.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/10.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/11.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/12.webp" alt="Product 1">
            </a>
        </section>
        <section class="grid-section two-columns">
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/13.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/14.webp" alt="Product 1">
            </a>
        </section>
        <section class="grid-section three-columns">
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/15.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/16.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/17.webp" alt="Product 1">
            </a>
        </section>
        <section class="grid-section four-columns">
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/19.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/20.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/21.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/22.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/23.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/24.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/25.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/26.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/27.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/28.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/29.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/30.webp" alt="Product 1">
            </a>
            
        </section>
        <section class="grid-section three-columns">
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/31.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/18.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/32.webp" alt="Product 1">
            </a>
           
        </section>

        <section class="grid-section four-columns">
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/33.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/34.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/35.webp" alt="Product 1">
            </a>
            <a class="grid-item" href="">
                <img src="../../../../assets/img/landings/holiday/36.webp" alt="Product 1">
            </a>
            </section>
            <section class="grid-section three-columns">
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/37.webp" alt="Product 1">
                </a>
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/38.webp" alt="Product 1">
                </a>
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/39.webp" alt="Product 1">
                </a>
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/41.webp" alt="Product 1">
                </a>
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/40.webp" alt="Product 1">
                </a>
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/42.webp" alt="Product 1">
                </a>
            </section>
            <section class="grid-section four-columns">
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/43.webp" alt="Product 1">
                </a>
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/44.webp" alt="Product 1">
                </a>
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/45.webp" alt="Product 1">
                </a>
                <a class="grid-item" href="">
                    <img src="../../../../assets/img/landings/holiday/46.webp" alt="Product 1">
                </a>
                </section>
        
    </main>