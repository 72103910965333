import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { catchError, finalize } from 'rxjs/operators';
import { ISignInResponse } from '../models/auth/signIn';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SnackBarService } from './snackbarService';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { LoaderService } from './loader.service';
import { LocalStorageService } from './local-storage.service';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  totalRequests = 0
  constructor(private snackBar: SnackBarService,
    private router: Router,
    private translate: TranslateService,
    private loadingService: LoaderService,
    private localStorageService: LocalStorageService
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.totalRequests++;
    this.loadingService.setLoading(true);
    const session = 720;
    const tempBasketSession = 24;
    const now = new Date().getTime();
    const signinTime = this.localStorageService.getItem('signinTime');
    const tempBasketTime = this.localStorageService.getItem('tempBasketPeriod');
    if (signinTime !== null && signinTime !== undefined) {
      const sessionPeriod = Number(signinTime);
      if (now - sessionPeriod > session * 60 * 60 * 1000) {
        this.localStorageService.removeItem('signinTime');
        this.localStorageService.removeItem('auth');
      }
    }
    if (tempBasketTime !== null && tempBasketTime !== undefined) {
      const sessionPeriod = Number(tempBasketTime);
      if (now - sessionPeriod > tempBasketSession * 60 * 60 * 1000) {
        this.localStorageService.removeItem('tempBasketPeriod');
        this.localStorageService.removeItem('tempBasket');
      }
    }

    const authArg: string | null = this.localStorageService.getItem('auth');

    if (authArg !== null && authArg !== undefined) {
      const authData: ISignInResponse | null = JSON.parse(authArg);
      if (authData ? authData.Token : null) {
        if (request.url.includes('amerikadaniste.com/api')) {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + (authData ? authData.Token : '')) });
        }
      }

    }

    if (!request.url.includes('https://translate.googleapis.com')) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    }

    if (request.url.includes('getBfmProductList')) {
      console.log('getBfmProductList');
      
      request = request.clone({
        setHeaders: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: '0'
        }
      })
    }


    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.loadingService.setLoading(false);
        }
      }),
      catchError(x => this.handleAuthError(x))
    );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {

    this.handleError(err.status);
    return throwError(err);
  }


  private handleError(error: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    switch (error) {

      case 401 || 402 || 403:
        this.snackBar.error(this.translate.instant('UNAUTHORIZED_PROCESS_PLEASE_LOGIN'));
        console.log(1);
        
        this.router.navigate(['signin'], { queryParams: { backUrl: this.router.url } });
        break;
      case 500:
        this.snackBar.error(this.translate.instant('UNEXPECTED_ERROR_OCCURED'));
        break;
      case 400:
        this.snackBar.error(this.translate.instant('BAD_REQUEST_ERROR'));
        break;
      case 404:
        this.snackBar.error(this.translate.instant('404_NOT_FOUND'));
        break;
      case 405:
        this.snackBar.error(this.translate.instant('405_NOT_ALLOWED'));
        break;
      case 502:
        this.snackBar.error(this.translate.instant('502_BAD_GATEWAY'));
        break;
      default:
        this.snackBar.error(this.translate.instant('OTHER_ERRORS'));
        break;
    }
  }
}
