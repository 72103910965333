<div class="containerfull">
    <div class="header">
        @if(lang == 'tr') {
        <img class="bg-img" src="../../../../assets/img/landings/maximiles-black/lp-bg.webp" alt="">
        } @else {
            <img class="bg-img" src="../../../../assets/img/landings/maximiles-black/lp-bg-en.webp" alt="">
        }
        <div class="left">
            <div class="logo">
                <img src="../../../../assets/img/landings/maximiles-black/MaximilesLogo.png" alt="" />
            </div>
            @if(lang == 'tr') {
            <div class="title">
                Maximiles Black Kart Sahipleri<br>Amerikadaniste ile Dünyaya Açılıyor!
            </div>
            <div class="subtitle">31.07.2024 Tarihine Kadar Geçerli Bu Kampanyayla<br>Dünyayı Kapınıza
                Getiriyoruz!</div>
            <div class="text">Amerikadaniste olarak, Maximiles Black kart sahiplerine özel bu benzersiz kampanyayla yurt
                dışından alışverişi çok daha kolay, hızlı ve güvenli hale getiriyoruz! Maximiles Black sahipleri için
                hazırladığımız Tanışma Paketi ile, 3 ay boyunca üyeliğiniz ücretsiz ve Amerika’dan ilk kargo
                gönderiminizde %25 indirim kazanıyor olacaksınız. Ayrıca Global Posta Kutum powered by Shippn ile 30’dan
                fazla ülkeden yapacağınız alışverişlerin ilk 3 kargosunda geçerli olacak %15 kargo indirimi ile global
                alışverişin tadına varacaksınız! Üyeliğinizi aktif etmek için ve kargo indiriminden yararlanmak için
                yalnızca ‘MAXI25’ kodunu kullanmanız yeterli.
                <div class="spantext">Amerikadaniste üyeliğiniz size neler kazanacak?</div>

                - Amerika’da kişisel bir posta adresine sahip olacaksınız.<br>
                - Amerika’daki her türlü online mağazadan rahatça alışveriş yapabileceksiniz.<br>
                - Global Posta Kutum powered by Shippn hizmeti ile 31 farklı ülkeden dilediğiniz gibi özgürce alışveriş
                yapabileceksiniz.<br>
                - Türkiye’de bulunmayan marka ve modellere kolayca erişim sağlayacaksınız.<br>
                - Amerika ve Global Posta Kutum powered by Shippn sayesinde dünyanın dört bir yanında sunulan avantajlı
                kampanyalardan yararlanacaksınız.<br>
                - En yeni sezon ürünlerine ve son teknoloji ürünlere öncelikli erişim imkanı bulacaksınız.<br>
                - Gerçek Black Friday indirimlerinin tadını çıkaracaksınız.<br>
                - Gümrük süreçlerini sizin için yönetecek, ürünlerinize hızla ulaşmanızı sağlayacağız.<br>
                - “Benim İçin Al” servisimizle, kredi kartınızın geçmediği veya Amerika dışına gönderim yapmayan
                sitelerdeki alışverişlerinizde güvenle satın alma işlemini biz gerçekleştireceğiz.<br><br>

                Bu kampanya sadece 31.07.2024 tarihine kadar, Amerikadaniste’ye ilk kez üye olacak
                Maximiles Black kart sahiplerine yöneliktir. Önceden Amerikadaniste üyeliği başlatmış kişiler bu
                kampanyadan yararlanamazlar.
            </div>
            } @else {
            <div class="title">
                Maximiles Black Cardholders<br>Open Up to the World with Amerikadaniste!
            </div>
            <div class="subtitle">With This Campaign Valid from 31.07.2024,<br>We Bring the World to Your Doorstep!
            </div>
            <div class="text">At Amerikadaniste, we make international shopping much easier, faster, and safer with this
                exclusive campaign for Maximiles Black cardholders! With our Welcome Package for Maximiles Black
                holders, you will get 3 months of free membership and a 25% discount on your first shipment from the
                USA. Additionally, with Global Posta Kutum powered by Shippn, you will enjoy a 15% shipping discount on
                your first 3 shipments from over 30 countries, allowing you to fully experience global shopping! Simply
                use the code ‘MAXI25’ to activate your membership and take advantage of the shipping discount.
                <div class="spantext">What will your Amerikadaniste membership get you?</div>

                - You will have a personal postal address in the USA.<br>
                - You can easily shop from any online store in the USA.<br>
                - With the Global Posta Kutum powered by Shippn service, you will be able to shop freely from 31
                different countries.<br>
                - You will have easy access to brands and models not available in Turkey.<br>
                - Take advantage of promotional campaigns offered around the world through Amerikadaniste and Global
                Posta Kutum powered by Shippn.<br>
                - Get priority access to the latest season products and the latest technology items.<br>
                - Enjoy genuine Black Friday discounts.<br>
                - We will manage customs processes for you, ensuring fast delivery of your products.<br>
                - With our “Buy For Me” service, we will securely handle purchases on sites that do not accept your
                credit card or do not ship outside the USA.<br><br>

                This campaign is valid only from June 31.07.2024, for Maximiles Black cardholders who are signing up for
                Amerikadaniste for the first time. Those who have previously initiated an Amerikadaniste membership
                cannot benefit from this campaign.<br>
            </div>
            }
        </div>
        <div class="right">
            <div class="form-angular">
                <div class="header-angular">
                    <div class="user-logo">
                        <img src="../../../../../assets/img/landings/maximiles-black/icon.png" alt="">
                    </div>
                    <span>{{'KUVEYTTURK_16' | translate}} </span>
                    <span>{{'KUVEYTTURK_17' | translate}}</span>
                </div>
                <div class="form-container ">
                    <form [formGroup]="signupForm" class="signup-form">
                        <ng-template matStepLabel>{{'MEMBERSHIP_INFO' | translate}}</ng-template>
                        <div class="content-row">
                            <div class="content-item">
                                <input formControlName="name" class="inputSignup" [ngClass]="{'error':signupForm.controls['name'].touched && signupForm.controls['name'].invalid}"
                                    placeholder="{{'YOUR_NAME' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['name'].touched && signupForm.controls['name'].hasError('required')">
                                        {{'NAME_IS_REQUIRED' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="signupForm.controls['name'].touched && signupForm.controls['name'].hasError('minlength')">
                                        {{'ENTER_A_VALID_NAME' | translate}}
                                    </mat-error>
                            </div>
                            <div class="content-item">
                                <input formControlName="surname" class="inputSignup" [ngClass]="{'error':signupForm.controls['surname'].touched && signupForm.controls['surname'].invalid}"
                                    placeholder="{{'YOUR_SURNAME' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['surname'].touched && signupForm.controls['surname'].hasError('required')">
                                        {{'SURNAME_IS_REQUIRED' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="signupForm.controls['surname'].touched && signupForm.controls['surname'].hasError('minlength')">
                                        {{'ENTER_A_VALID_SURNAME' | translate}}
                                    </mat-error>
                            </div>
                        </div>
                        <div class="content-row">
                            <div class="content-item">
                                <input formControlName="email" type="email" class="inputSignup" [ngClass]="{'error':signupForm.controls['email'].touched && signupForm.controls['email'].invalid}"
                                    placeholder="{{'YOUR_EMAIL' | translate}}">
                                    <mat-error
                                            *ngIf="signupForm.controls['email'].touched && signupForm.controls['email'].hasError('email')">
                                            {{'ENTER_VALID_EMAIL' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="signupForm.controls['email'].touched && signupForm.controls['email'].hasError('required')">
                                            {{'EMAIL_IS_REQUIRED' | translate}}
                                        </mat-error>
                            </div>
                            <div class="content-item">
                                <input [type]="hidePassword ? 'password' : 'text'" formControlName="password"
                                    class="inputSignup" [ngClass]="{'error':signupForm.controls['password'].touched && signupForm.controls['password'].invalid}" placeholder="{{'YOUR_PASSWORD' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['password'].touched && signupForm.controls['password'].invalid">
                                            {{'VALID_PASSWORD_REQUIRED' | translate}}
                                    </mat-error>
                            </div>
                        </div>
                        <div class="content-row">
                            <div class="content-item">
                                <input formControlName="phone" mask='(000) 000 00 00' type="tel"
                                    (input)="onNumberChange($event)" class="inputSignup"  [ngClass]="{'error':signupForm.controls['phone'].touched && signupForm.controls['phone'].invalid}"
                                    placeholder="{{'YOUR_NUMBER' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['phone'].touched && signupForm.controls['phone'].hasError('required')">
                                        {{'NUMBER_IS_REQUIRED' | translate}}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="signupForm.controls['phone'].touched && signupForm.controls['phone'].hasError('minlength') || signupForm.controls['phone'].hasError('maxlength') ">
                                        {{'ENTER_A_VALID_NUMBER' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="signupForm.controls['phone'].touched && signupForm.controls['phone'].hasError('invalidNumber')">
                                        {{'INVALID_TR_PHONE' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="signupForm.controls['phone'].touched && signupForm.controls['phone'].hasError('countryCode')">
                                        {{'YOUR_PHONE_NUMBER_MUST_START_WITH_5' | translate}}
                                    </mat-error>
                            </div>
                            <div class="content-item">
                                <input formControlName="birthDate" [min]="minDate" [max]="maxDate"
                                    [matDatepicker]="picker" (focus)="picker.open()"
                                    class="inputSignup datePickerInputSignup"  [ngClass]="{'error':signupForm.controls['birthDate'].touched && signupForm.controls['birthDate'].invalid}"
                                    placeholder="{{'YOUR_BIRTH_DATE' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['birthDate'].touched && signupForm.controls.birthDate.hasError('required')">
                                        {{'BIRTH_DATE_IS_REQUIRED' | translate}}
                                    </mat-error>
                                <mat-datepicker #picker panelClass="signupAndContinueDatePanel"></mat-datepicker>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="form-footer">
                    <div class="checkbox-container">
                        <div class="checkbox-item">
                            <input type="checkbox" class="checkboxSignup"
                                [(ngModel)]="isCampaignPermission"><span>{{'I_WANT_TO_BE_INFORMED' | translate}}</span>
                        </div>
                        <div class="checkbox-item">
                            <input type="checkbox" [(ngModel)]="isUserAgreement"><a
                                (click)="openDialog($event)">{{'I_HAVE_READ_THE_USER_AGGREEMENT' | translate}}</a>
                        </div>
                    </div>
                    <div class="form-submit">
                        <div>
                            <form [formGroup]="reCaptchaForm" class="captcha-form">
                                <re-captcha #captchaElem [siteKey]="siteKey" (resolved)="resolved($event)"
                                    [size]="'normal'" [theme]="'light'" [type]="'image'">
                                </re-captcha>

                            </form>
                        </div>
                    </div>
                </div>
                <div class="signup-btn" (click)="signUpAndContinue()">{{'KUVEYTTURK_18' | translate}}</div>
            </div>
        </div>



    </div>
</div>