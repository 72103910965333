import { Component, ElementRef, HostListener, OnInit, ViewChild, afterNextRender, afterRender } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../../../src/app/core/services/app/auth.service';
import { ShoppingService } from '../../../../../../src/app/core/services/app/shopping.service';
import { SnackBarService } from '../../../../../../src/app/core/services/snackbarService';
import { MainSliderRequestModel } from '../../../../../../src/app/shareds/components/main-slider/models/main-slider-request.model';
import { MainSliderResponseModel } from '../../../../../../src/app/shareds/components/main-slider/models/main-slider-response.model';
import { MainSliderService } from '../../../../../../src/app/shareds/components/main-slider/services/main-slider.service';
import { LanguageEnum } from '../../../../../../src/app/shareds/enums/language.enum';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { WindowReferenceService } from '../../../../core/services/window-reference.service';
import { CommonModule } from '@angular/common';
import { DeferMainComponent } from '../defer-main/defer-main.component';
import { CarouselComponent } from '../../../../shareds/modules/carousel/carousel.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MailBoxService } from '../../../../core/services/app/mailbox.service';

@Component({
  standalone: true,
  imports: [CommonModule,
    TranslateModule,
    CarouselComponent,
    DeferMainComponent,
    MatIconModule,
    MatTooltipModule,
    
  ],
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  @HostListener('window:resize')
  onResize() {
    if (this._windowRef?.nativeWindow?.innerWidth < 768) {
      this.platform = 2 // mobile device
      this.isMobile = true
      this.getMainSlider()
    } else {
      this.platform = 1
      this.isMobile = false
      this.getMainSlider()
    }
  }
  @ViewChild('addProductLink') addProductLink: ElementRef | undefined;
  isLoading = false;
  activeLang = this.translate.currentLang;
  searchParam = new UntypedFormControl();

  paidMember = false;
  isAuth = false;
  bfmHide = false;
  auth: any
  selectedLangId = LanguageEnum.TURKISH_LANG_ID;
  mainSliderList: MainSliderResponseModel[] = [
    /*
    {
      Type: 1,
      Url: '',
      ImgUrl: '../../../../../assets/img/default-slide-bg.webp',
      Title: '',
      HtmlCode: ''
    }*/
  ];
  mainCarouselList: any[] = [];
  isEmpityMainSlider = false
  isMobile = false
  platform = 1 // desktop device
  warningMessage = ''
  isAfterRender = false
  currentUser:any
  usMailBoxCount = 0
  packageWaitingTime = 0
  toolTypeTemplate:any

  constructor(
    private translate: TranslateService,
    public router: Router,
    private _windowRef: WindowReferenceService,
    private snackBar: SnackBarService,
    private authService: AuthService,
    private shoppingService: ShoppingService,
    private mainSliderService: MainSliderService,
    private localStorageService: LocalStorageService,
    private mailBoxService: MailBoxService,
  ) {
    this.translate.defaultLang
  }

  ngOnInit() {
    this.toolTypeTemplate = `<span></span>`
    this.auth = JSON.parse(this.localStorageService.getItem('auth') || '{}');
    if(this.auth.Token) {
      this.isAuth = true
    } else {
      this.isAuth = false
    }
    this.mainPageWarning()
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.findSelectedLangId(event.lang)
      this.getMainSlider();
    });
    this.findSelectedLangId(this.translate.currentLang)
    this.onResize()

    this.localStorageService.removeItem('buyForMeMainPage');
    
    if (this.isAuth) {
      this.currentUser = JSON.parse(this.localStorageService.getItem('currentUser') + '');
      this.authService.getCurrentUser().subscribe((data) => {
        this.paidMember = data.Data.PaidMember;
      }, (err) => {
        this.snackBar.error(err.error.Message);
      });
    }

    if (this.isAuth && this.auth.PaidMember) {
      this.getMailBox()
    }
   
  }

  getMailBox() {
    let dateArray = []
    let bigDate = 0
    let oneDay = 1000*60*60*24;
    let nowDate = new Date().getTime()
    this.mailBoxService.getmailBox().subscribe(result => {
      this.usMailBoxCount = result.Data.length
      dateArray = (result.Data as any[]).map(m => {
        return new Date(m.Date).getTime()
      })
      bigDate = Math.max(...dateArray)
      this.packageWaitingTime = Math.round((bigDate - nowDate) / oneDay)
      if(this.packageWaitingTime < 0) {
        this.packageWaitingTime = 0
      }
    })
  }

  findSelectedLangId(language: string) {
    language == 'tr' ? this.selectedLangId = LanguageEnum.TURKISH_LANG_ID : this.selectedLangId = LanguageEnum.ENGLISH_LANG_ID;
  }

  mainPageWarning() {
    this.authService.mainPageWarning().subscribe(result => {
      if (result.Result == 200) {
        this.warningMessage = result.Data
      }
    })
  }
  
  getMainSlider() {
    const payload: MainSliderRequestModel = {
      Position: 1,
      Platform: this.platform,
      SiteId: 1,
      IsLive: 1,
      IsTest: 1,
      LangId: this.selectedLangId,
      IsUserLoggedIn: this.isAuth ? true : false
    }

    this.mainSliderService.getMainSlide(payload).subscribe(result => {
      if (result.Result === 200 && result.Data.length) {
        this.isEmpityMainSlider = true
        this.mainSliderList = result.Data
      }
    })

  }

  navigateRouter = (page: any, isClickByForMeButton?: boolean) => {
    let object = {
      addProductLink: this.addProductLink?.nativeElement.value,
      isClickByForMeButton: isClickByForMeButton,
      price: 0
    }

    if (isClickByForMeButton) {
      this.localStorageService.setItem('buyForMeMainPage', JSON.stringify(object))
      if (this.isAuth) {
        this.router.navigate([this.translate.currentLang + '/user/buy-for-me/new']);
        return;
      }
    }
    this.router.navigate(page);
  }

  copyUsAddress() {
    let address = this.currentUser?.UsAddress?.AddressOwner + ' ' + this.currentUser?.UsAddress?.AddressLine1 + ' ' + this.currentUser?.UsAddress?.AddressLine2 + ' ' + this.currentUser?.UsAddress?.City + ', ' + this.currentUser?.UsAddress?.State + ' ' + this.currentUser?.UsAddress?.ZipCode + ' ' + this.currentUser?.UsAddress?.USPhone + ' / ' + this.currentUser?.UsAddress?.USPhoneExt
    navigator.clipboard.writeText(address);
    this.snackBar.success(this.translate.instant('COPIED_TO_CLIPBOARD'))
  }

  usMailboxCountClick() {
    this.router.navigate([this.translate.currentLang + '/user/mailbox/list'])
  }

  

}
