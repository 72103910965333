import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../core/services/local-storage.service';

@Component({
  standalone:true,
  selector: 'app-is-ai-reliable',
  templateUrl: './is-ai-reliable.component.html',
  styleUrl: './is-ai-reliable.component.scss',
  imports:[TranslateModule,RouterModule]
})
export class IsAiReliableComponent implements OnInit {

  currentLang = 'tr'
  isAuth = false
  auth:any
  constructor(
    private localStorageService:LocalStorageService,
    private router:Router,
    private translate: TranslateService
    ) {}

  ngOnInit(): void {
    this.translate.currentLang
    this.isAuth = JSON.parse(this.localStorageService.getItem('auth') + '');
    this.auth = JSON.parse(this.localStorageService.getItem('auth') + '');
  }

  navigateRouter(url:string): void {
    this.router.navigate([this.translate.currentLang + '/' + url])
  }

}
