import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../../../../../src/environments/environment";
import { MainSliderRequestModel } from "../models/main-slider-request.model";

@Injectable({ providedIn: 'root' })
export class MainSliderService {

  constructor(
    private http: HttpClient) {
  }

  getMainSlide(payload: MainSliderRequestModel): Observable<any> {
    return this.http.post<any>(environment.api + 'banner/getList', payload);
     //return this.http.post<any>('https://amerikadaniste.com/api/banner/getList', payload);
  }
}