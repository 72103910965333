import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrl: './holiday.component.scss',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule
  ]
})
export class HolidayComponent implements OnInit {

  constructor(public translate:TranslateService) {}

  ngOnInit(): void {}

}
