<div class="garanti-bank-container">
    <div class="wrapper">
        <div class="left">
            <h1 [innerHTML]="'GARANTI_1' | translate"> </h1>
            <br>

            <p>{{'GARANTI_2' | translate}}</p>
            <h3> {{'KUVEYTTURK_4' | translate}} </h3>
            <h3>{{'KUVEYTTURK_5' | translate}}</h3>

            <p>
                {{'KUVEYTTURK_6' | translate}}
            </p>
            <p>{{'KUVEYTTURK_7' | translate}}</p>
            <p>{{'KUVEYTTURK_8' | translate}}</p>
            <p>{{'KUVEYTTURK_9' | translate}}</p>
            <p>{{'KUVEYTTURK_10' | translate}}</p>
            <p>{{'KUVEYTTURK_11' | translate}}</p>
            <p>{{'KUVEYTTURK_12' | translate}}</p>
            <p>{{'KUVEYTTURK_13' | translate}}</p>
            <p>{{'KUVEYTTURK_14' | translate}} </p>

            <p>{{'GARANTI_3' | translate}}</p>
        </div>
        <div class="right">
            <div class="header">
                <img src="../../../../../assets/img/landings/garanti/garanti-logo.webp" alt="" class="kuveyt-turk-logo">
                <img src="../../../../../assets/img/landings/garanti/garanti-card.webp" alt="" class="bg">
            </div>
            <div class="form">
                <div class="header">
                    <div class="user-logo">
                        <img src="../../../../../assets/img/landings/kuvet-turk/Icon.webp" alt="">
                    </div>
                    <span>{{'KUVEYTTURK_16' | translate}} </span>
                    <span>{{'KUVEYTTURK_17' | translate}}</span>
                </div>
                <div class="form-container">
                    <form [formGroup]="signupForm" class="signup-form">
                        <ng-template matStepLabel>{{'MEMBERSHIP_INFO' | translate}}</ng-template>
                        <div class="content-row">
                            <div class="content-item">
                                <input formControlName="name" class="inputSignup" [ngClass]="{'error':signupForm.controls['name'].touched && signupForm.controls['name'].invalid}"
                                    placeholder="{{'YOUR_NAME' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['name'].touched && signupForm.controls['name'].hasError('required')">
                                        {{'NAME_IS_REQUIRED' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="signupForm.controls['name'].touched && signupForm.controls['name'].hasError('minlength')">
                                        {{'ENTER_A_VALID_NAME' | translate}}
                                    </mat-error>
                            </div>
                            <div class="content-item">
                                <input formControlName="surname" class="inputSignup" [ngClass]="{'error':signupForm.controls['surname'].touched && signupForm.controls['surname'].invalid}"
                                    placeholder="{{'YOUR_SURNAME' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['surname'].touched && signupForm.controls['surname'].hasError('required')">
                                        {{'SURNAME_IS_REQUIRED' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="signupForm.controls['surname'].touched && signupForm.controls['surname'].hasError('minlength')">
                                        {{'ENTER_A_VALID_SURNAME' | translate}}
                                    </mat-error>
                            </div>
                        </div>
                        <div class="content-row">
                            <div class="content-item">
                                <input formControlName="email" type="email" class="inputSignup" [ngClass]="{'error':signupForm.controls['email'].touched && signupForm.controls['email'].invalid}"
                                    placeholder="{{'YOUR_EMAIL' | translate}}">
                                    <mat-error
                                            *ngIf="signupForm.controls['email'].touched && signupForm.controls['email'].hasError('email')">
                                            {{'ENTER_VALID_EMAIL' | translate}}
                                        </mat-error>
                                        <mat-error *ngIf="signupForm.controls['email'].touched && signupForm.controls['email'].hasError('required')">
                                            {{'EMAIL_IS_REQUIRED' | translate}}
                                        </mat-error>
                            </div>
                            <div class="content-item">
                                <input [type]="hidePassword ? 'password' : 'text'" formControlName="password"
                                    class="inputSignup" [ngClass]="{'error':signupForm.controls['password'].touched && signupForm.controls['password'].invalid}" placeholder="{{'YOUR_PASSWORD' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['password'].touched && signupForm.controls['password'].invalid">
                                            {{'VALID_PASSWORD_REQUIRED' | translate}}
                                    </mat-error>
                            </div>
                        </div>
                        <div class="content-row">
                            <div class="content-item">
                                <input formControlName="phone" mask='(000) 000 00 00' type="tel"
                                    (input)="onNumberChange($event)" class="inputSignup"  [ngClass]="{'error':signupForm.controls['phone'].touched && signupForm.controls['phone'].invalid}"
                                    placeholder="{{'YOUR_NUMBER' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['phone'].touched && signupForm.controls['phone'].hasError('required')">
                                        {{'NUMBER_IS_REQUIRED' | translate}}
                                    </mat-error>
                                    <mat-error
                                        *ngIf="signupForm.controls['phone'].touched && signupForm.controls['phone'].hasError('minlength') || signupForm.controls['phone'].hasError('maxlength') ">
                                        {{'ENTER_A_VALID_NUMBER' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="signupForm.controls['phone'].touched && signupForm.controls['phone'].hasError('invalidNumber')">
                                        {{'INVALID_TR_PHONE' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="signupForm.controls['phone'].touched && signupForm.controls['phone'].hasError('countryCode')">
                                        {{'YOUR_PHONE_NUMBER_MUST_START_WITH_5' | translate}}
                                    </mat-error>
                            </div>
                            <div class="content-item">
                                <input formControlName="birthDate" [min]="minDate" [max]="maxDate"
                                    [matDatepicker]="picker" (focus)="picker.open()"
                                    class="inputSignup datePickerInputSignup"  [ngClass]="{'error':signupForm.controls['birthDate'].touched && signupForm.controls['birthDate'].invalid}"
                                    placeholder="{{'YOUR_BIRTH_DATE' | translate}}">
                                    <mat-error *ngIf="signupForm.controls['birthDate'].touched && signupForm.controls.birthDate.hasError('required')">
                                        {{'BIRTH_DATE_IS_REQUIRED' | translate}}
                                    </mat-error>
                                <mat-datepicker #picker panelClass="signupAndContinueDatePanel"></mat-datepicker>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="form-footer">
                    <div class="checkbox-container">
                        <div class="checkbox-item">
                            <input type="checkbox" class="checkboxSignup"
                                [(ngModel)]="isCampaignPermission"><span>{{'I_WANT_TO_BE_INFORMED' | translate}}</span>
                        </div>
                        <div class="checkbox-item">
                            <input type="checkbox" [(ngModel)]="isUserAgreement"><a (click)="openDialog($event)">{{'I_HAVE_READ_THE_USER_AGGREEMENT' | translate}}</a>
                        </div>
                    </div>
                    <div class="form-submit">

                        <form [formGroup]="reCaptchaForm" class="captcha-form">
                            <re-captcha #captchaElem [siteKey]="siteKey" (resolved)="resolved($event)" [size]="'normal'"
                                [theme]="'light'" [type]="'image'">
                            </re-captcha>
                        </form>

                    </div>
                </div>
                <div class="signup-btn" (click)="signUpAndContinue()">{{'KUVEYTTURK_18' | translate}}</div>
            </div>
        </div>
    </div>
</div>