<div class="buy-for-me-container">
    <div class="banner">
        <div class="banner-text">
            <div class="banner-text-wrapper">

                @if(!isAuth) {
                <h1 style="font-size: 28px;">{{'AI_ADVANTAGE_1' | translate}}</h1>
                <a (click)="navigateRouter('signup')" class="btn-type-1" style="margin-top: 24px;"> {{'TRY_FOR_FREE' |
                    translate}} </a>
                }
                @if(isAuth && auth?.PaidMember) {
                <h1 style="font-size: 28px;">{{'AI_ADVANTAGE_1' | translate}}</h1>
                }

            </div>
        </div>
        <img src="../../../../../assets/img/ai-advantage/banner.webp" alt="" class="" style="box-sizing:content-box">

    </div>
    <div class="content-container">
        <div class="step-1">
            <p>{{'AI_ADVANTAGE_2' | translate}}</p>
        </div>
        <div class="full-width">
            <div class="full-width-wrapper">
                <div class="item-1">
                    <div class="left">
                        <h2>{{'AI_ADVANTAGE_3' | translate}}</h2>
                        <p>{{'AI_ADVANTAGE_4' | translate}}</p>
                    </div>
                    <div class="right">
                        <img src="../../../../../assets/img/ai-advantage/1.webp" alt="">
                    </div>
                    <img src="../../../../assets/icons/arrow-left-down.png" class="arrow-down arrow-left-down" alt="">
                </div>
               
            </div>
        </div>
        <div class="item-1 row-reverse">
            <div class="left">
                <h2>{{'AI_ADVANTAGE_5' | translate}}</h2>
                <p>{{'AI_ADVANTAGE_6' | translate}}</p>
            </div>
            <div class="right">
                <img src="../../../../../assets/img/ai-advantage/2.webp" alt="">
            </div>
            <img src="../../../../assets/icons/arrow-right-down.png" class="arrow-down arrow-right-down" alt="">
        </div>
        <div class="full-width">
            <div class="full-width-wrapper">
                <div class="item-1">
                    <div class="left">
                        <h2>{{'AI_ADVANTAGE_7' | translate}}</h2>
                        <p>{{'AI_ADVANTAGE_8' | translate}}</p>
                    </div>
                    <div class="right">
                        <img src="../../../../../assets/img/ai-advantage/3.webp" alt="">
                    </div>
                </div>
            </div>
        </div>
        @if(!isAuth) {
        <div class="full-width" style="background-color: #1C1C1C;color:#fff">
            <div class="full-width-wrapper try-for-free"
                style="flex-direction: row; justify-content: space-between;align-items: center;padding: 24px 12px;">
                <span style="max-width: 100%;width: 550px;">{{'AI_ADVANTAGE_9' | translate}}</span>
                @if(!isAuth) {
                <a (click)="navigateRouter('signup')" class="btn-type-1"> {{'SIGN_UP_NOW' | translate}} </a>
                }
            </div>
        </div>
        }
        <div class="item-1 row-reverse">
            <div class="left">
                <h2>{{'AI_ADVANTAGE_10' | translate}}</h2>
                <p>{{'AI_ADVANTAGE_11' | translate}}</p>
            </div>
            <div class="right">
                <img src="../../../../../assets/img/ai-advantage/4.webp" alt="">
            </div>
            <img src="../../../../assets/icons/arrow-left-down.png" class="arrow-down arrow-left-down" alt="">
        </div>
        <div class="full-width">
            <div class="full-width-wrapper">
                <div class="item-1 ">
                    <div class="left">
                        <h2>{{'AI_ADVANTAGE_12' | translate}}</h2>
                        <p>{{'AI_ADVANTAGE_13' | translate}}</p>
                    </div>
                    <div class="right">
                        <img src="../../../../../assets/img/ai-advantage/5.webp" alt="">
                    </div>
                    <img src="../../../../assets/icons/arrow-right-down.png" class="arrow-down arrow-right-down" alt="">
                </div>
            </div>
        </div>

        <div class="item-1 row-reverse">
            <div class="left">
                <h2>{{'AI_ADVANTAGE_14' | translate}}</h2>
                <p>{{'AI_ADVANTAGE_15' | translate}}</p>
            </div>
            <div class="right">
                <img src="../../../../../assets/img/ai-advantage/6.webp" alt="">
            </div>
            <img src="../../../../assets/icons/arrow-left-down.png" class="arrow-down arrow-left-down" alt="">
        </div>
        <div class="full-width" style="background-color: #1C1C1C;color:#fff">
            <div class="full-width-wrapper try-for-free"
                style="flex-direction: row; justify-content: space-between;align-items: center;padding: 24px 12px;">
                <span style="max-width: 100%;width: 550px;">{{'AI_ADVANTAGE_16' | translate}}</span>

                <a (click)="navigateRouter('signup')" class="btn-type-1"> {{'HOW_IT_WORKS' | translate}} </a>

            </div>
        </div>
        <div class="full-width">
            <div class="full-width-wrapper">
                <div class="item-1">
                    <div class="left">
                        <h2 innerHTML="{{'AI_ADVANTAGE_17' | translate}}"></h2>
                        <p>{{'AI_ADVANTAGE_18' | translate}}</p>
                    </div>
                    <div class="right">
                        <img src="../../../../../assets/img/ai-advantage/7.webp" alt="">
                    </div>
                    <img src="../../../../assets/icons/arrow-right-down.png" class="arrow-down arrow-right-down" alt="">
                </div>
            </div>
        </div>
        <div class="item-1 row-reverse">
            <div class="left">
                <h2>{{'AI_ADVANTAGE_19' | translate}}</h2>
                <p>{{'AI_ADVANTAGE_20' | translate}}</p>
            </div>
            <div class="right">
                <img src="../../../../../assets/img/ai-advantage/8.webp" alt="">
            </div>
            <img src="../../../../assets/icons/arrow-left-down.png" class="arrow-down arrow-left-down" alt="">
        </div>
        <div class="full-width">
            <div class="full-width-wrapper">
                <div class="item-1">
                    <div class="left">
                        <h2>{{'AI_ADVANTAGE_21' | translate}}</h2>
                        <p>{{'AI_ADVANTAGE_22' | translate}}</p>
                    </div>
                    <div class="right">
                        <img src="../../../../../assets/img/ai-advantage/9.webp" alt="">
                    </div>
                    <img src="../../../../assets/icons/arrow-right-down.png" class="arrow-down arrow-right-down" alt="">
                </div>
            </div>
        </div>
        <div class="item-1 row-reverse">
            <div class="left">
                <h2>{{'AI_ADVANTAGE_23' | translate}}</h2>
                <p>{{'AI_ADVANTAGE_24' | translate}}</p>
            </div>
            <div class="right">
                <img src="../../../../../assets/img/ai-advantage/10.webp" alt="">
            </div>

        </div>
        @if(!isAuth) {
        <div class="full-width" style="background-color: #1C1C1C;color:#fff">
            <div class="full-width-wrapper try-for-free"
                style="flex-direction: row; justify-content: space-between;align-items: center;padding: 24px 12px;">
                <span style="max-width: 100%;width: 550px;">{{'AI_ADVANTAGE_9' | translate}}</span>
                @if(!isAuth) {
                <a (click)="navigateRouter('signup')" class="btn-type-1"> {{'SIGN_UP_NOW' | translate}} </a>
                }
            </div>
        </div>
        }

        <div class="how-it-works-container">
            <h2>{{'AI_ADVANTAGE_26' | translate}}</h2>
            <p>{{'AI_ADVANTAGE_27' | translate}}</p>
            <br>
            <div class="row-layout-center full-row header">
                <img src="assets/img/is-amerikadaniste-reliable/ai-logo.webp" alt="" class="logo">
            </div>
            <div class="row-layout-center full-row content">
                <div class="row-layout-space-around how-it-works-items">
                    <div class="how-it-works-item">
                        <img src="/assets/icons/HIW-1-svg.webp" async alt="image">
                        <span class="how-it-works-label">{{'GET_YOUR_US_ADDRESS_MAIN' | translate}}</span>
                    </div>
                    <img src="/assets/icons/arrow-right-down.svg" alt="" class="arrow-1">
                    <img src="/assets/icons/arrow-right.svg" alt="" class="arrow-4">
                    <div class="how-it-works-item down">
                        <img src="/assets/icons/HIW-2.png" async alt="image">
                        <span class="how-it-works-label">{{'SHOP_FROM_THE_SITES_YOU_WISH' | translate}}</span>
                    </div>
                    <img src="/assets/icons/arrow-right.svg" alt="" class="arrow-2">
                    <img src="/assets/icons/arrow-right.svg" alt="" class="arrow-4">
                    <div class="how-it-works-item down">
                        <img src="/assets/icons/HIW-3.svg" async alt="image">
                        <span class="how-it-works-label">{{'WE_DELIVER_IT_TO_YOUR_TR_ADDRESS' | translate}}</span>
                    </div>
                    <img src="/assets/icons/arrow-right-up.svg" alt="" class="arrow-3">
                    <img src="/assets/icons/arrow-right.svg" alt="" class="arrow-4">
                    <div class="how-it-works-item">
                        <img src="/assets/icons/HIW-4.svg" async alt="image">
                        <span class="how-it-works-label">{{'IS_AI_RELIABLE_26' | translate}}</span>
                    </div>
                </div>
            </div>

        </div>



    </div>
</div>