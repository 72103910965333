import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { GlobalConstants } from '../../../core/utilities/global-constants';
import StaticTexts from '../../../../assets/static-texts/static-texts';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../../core/services/snackbarService';
import { DateFormatService } from '../../../core/services/date-format.service';
import { AuthService } from '../../../core/services/app/auth.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { PasswordStrengthValidator } from '../../../core/utilities/password-strength-validator';
import { ISignUp } from '../../../core/models/auth/signUp';
import { DateFormatEnum } from '../../../core/enums/date-format.enum';
import { ISignIn } from '../../../core/models/auth/signIn';
import { UserAgreementComponent } from '../../../shareds/components/agreements/user-agreement/user-agreement.component';
import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha';
import { GeneralService } from '../../../core/services/general.service';

@Component({
  standalone:true,
  selector: 'app-garanti-bank',
  templateUrl: './garanti-bank.component.html',
  styleUrl: './garanti-bank.component.scss',
  imports:[
    NgxMaskDirective,
    NgxMaskPipe,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    RecaptchaModule,
    MatCheckboxModule,
    RouterModule
  ],
  providers: [provideNgxMask()]
})
export class GarantiBankComponent implements OnInit , AfterViewInit{
  @ViewChild('captchaElem') captchaElem!: RecaptchaComponent;
  signupForm!: UntypedFormGroup;
  reCaptchaForm!: UntypedFormGroup;
  hidePassword = true;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  currentDay = new Date().getDate();
  minDate = new Date(this.currentYear - 80, 0, 1);
  maxDate = new Date(this.currentYear - 18, this.currentMonth, this.currentDay);
  isUserAgreement = false;
  isCampaignPermission = false;
  userAgreementText = '';
  signupReCaptchaResponse = '';
  isCaptchaValid = false;
  siteKey = GlobalConstants.siteKey;
  isLoading = false;
  lang = 'tr';
  texts = StaticTexts.signupTexts;
  displayText = this.texts.TR;
  isBuffer = false

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private dateFormatService: DateFormatService,
    private authService: AuthService,
    private localStorageService:LocalStorageService,
    private cookieService: CookieService,
    private generalService:GeneralService
  ) {}

  ngOnInit(): void {
    this.initForms()
    this.setDisplayText();
    this.translate.onLangChange.subscribe((event) => {
      this.setDisplayText();
    });
  }

  ngAfterViewInit(): void {
    this.isBuffer = true
  }

  initForms() {
    this.signupForm = this.formBuilder.group({
      name: ['', {validators: [Validators.required, Validators.minLength(2)]}],
      surname: ['', {validators: [Validators.required, Validators.minLength(2)]}],
      email: ['', {validators: [Validators.required, Validators.email]}],
      phone: ['', {validators: [Validators.required, Validators.minLength(10), Validators.maxLength(10)]}],
      password: ['', {validators: [Validators.required, Validators.minLength(6), PasswordStrengthValidator]}],
      birthDate: ['', {validators: [Validators.required]}]
    });
    this.reCaptchaForm = this.formBuilder.group({
      recaptcha: ['', {validators: [Validators.required]}]
    });
  }

  signUpAndContinue = () => {
   
    if (!this.signupForm.valid) {
      this.snackBar.error(this.translate.instant('CONTROL_THE_INFO_ENTERED'));
      return;
    }
    if (!this.isUserAgreement) {
      this.snackBar.error(this.translate.instant('GIVE_PERMISSION_TO_USER_AGREEMENT'));
      return;
    }
    if (this.signupReCaptchaResponse === '') {
      this.snackBar.error(this.translate.instant('COMPLETE_THE_RECAPTCHA'));
      return;
    }

    // if (this.selectedAddressOption === '' || this.selectedAddressOption === null || this.selectedAddressOption === undefined){
    //   this.snackBar.error(this.translate.instant('PLEASE_SELECT_AN_ADDRESS'));
    //   return;
    // }

    // if (this.selectedAddressOption === 'tr'){
    //   if (!this.addressForm.valid){
    //     this.snackBar.error(this.translate.instant('CONTROL_THE_INFO_ENTERED'));
    //     return;
    //   }
    // }

    const param: ISignUp = {
      Name: this.signupForm.controls['name'].value,
      Surname: this.signupForm.controls['surname'].value,
      Mail: this.signupForm.controls['email'].value,
      GSM: {
        Code: this.signupForm.controls['phone'].value.slice(0, 3),
        PhoneNumber: this.signupForm.controls['phone'].value.slice(3),
      },
      Password: this.signupForm.controls['password'].value,
      BirthDate: this.dateFormatService.formatDate(this.signupForm.controls['birthDate'].value , DateFormatEnum.DD_MM_YYYY),
      CampaignPermission: this.isCampaignPermission,
      SiteId: 1,
      RegisteredFromBfm: true,
      LanguageID: this.translate.currentLang === 'en' ? 2 : 1,
      reCaptchaResponse: this.signupReCaptchaResponse,
      //Channel: "web"
    };

    this.authService.signUp(param).subscribe((data) => {
        if (data.Result === 401) {
          this.snackBar.error(data.Message);
          
          this.handleExpire();
          return;
        } else if (data.Result === 400 && data.ReCaptchaResponse === this.signupReCaptchaResponse) {
          this.snackBar.error(this.translate.instant('WE_COULDNT_VALIDATE_THAT_YOU_ARE_A_HUMAN'));
          
          this.handleExpire();
          return;
        } else if (data.Result === 200) {
          this.cookieService.set('bearer', data.Data);
          this.cookieService.set('PaidMember', 'false');
          if (this.cookieService.check('bearer')) {
            this.snackBar.success(this.translate.instant('SIGNUP_FIRST_STEP_SUCCESS'));
            this.authService.signIn(this.signupForm.controls['email'].value, this.signupForm.controls['password'].value).subscribe((signinData: ISignIn) => {
              if (signinData.Data) {
                
                const now = new Date().getTime();
                this.localStorageService.setItem('signinTime', now.toString());
                this.localStorageService.setItem('auth', JSON.stringify(signinData.Data));
                this.generalService.webViewPostMessage(signinData.Data.Token)
                this.authService.setAuthInformation(signinData.Data);
                this.navigateRouter(['/signup']);
              } else {
                
                this.snackBar.error(this.translate.instant('AN_ERROR_OCCURED_DURING_SIGNUP_PROCESS'));
                this.handleExpire();
                return;
              }
            }, (err) => {
              
              this.snackBar.error(this.translate.instant('AN_ERROR_OCCURED_DURING_SIGNUP_PROCESS'));
              this.handleExpire();
              return;
            });
          }
        }
      },
      (err) => {
        
        this.snackBar.error(this.translate.instant('AN_ERROR_OCCURED_DURING_SIGNUP_PROCESS'));
        this.handleExpire();
        return;
      });
  }

  onNumberChange = (event: Event) => {
    const value = (event.target as HTMLInputElement).value;

    if (value.toString().split('')[1] !== '5') {
      this.signupForm.controls['phone'].setErrors({invalidNumber : true});
    } else {
      this.signupForm.controls['phone'].setErrors(null);
    }
  }

  openDialog(event: any) {
    event.preventDefault();
    const dialogRef = this.dialog.open(UserAgreementComponent, {
      width: '800px',
      height: '800px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.result === 'agree'){
        this.isUserAgreement = true;
      }
    });
  }

  handleExpire = () => {
    this.captchaElem.reset();
    this.signupReCaptchaResponse = '';
  }

  resolved(captchaResponse: string | null) {
    if(captchaResponse != null) {
      this.isCaptchaValid = true;
      this.signupReCaptchaResponse = captchaResponse
    }
  }
 
  navigateRouter(page: any) {
    this.router.navigate(page);
  }

  setDisplayText = () => {
    if (this.translate.currentLang === 'tr') {
      this.displayText = this.texts.TR;
      this.lang = 'tr';
    } else {
      this.displayText = this.texts.EN;
      this.lang = 'en';
    }
    this.userAgreementText = this.displayText.UserAgreementText;
  }
}
