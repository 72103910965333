<div class="container">
    <div class="wrapper">
        <div class="header">
            <h1>{{'CURRENT_OPPOR' | translate}}</h1>
        </div>
        <div class="body">
            @for(item of mainSliderList;track $index) {
            <a class="card" [href]="item.Url" target="_blank"
                (click)="googleAnalytics(item.Url)">
                <img [src]="item.ImgUrl" alt="" class="product-image" loading="lazy">
            </a>
            }
        </div>
    </div>
</div>