import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  standalone:true,
  selector: 'app-ai-advantage',
  templateUrl: './ai-advantage.component.html',
  styleUrl: './ai-advantage.component.scss',
  imports:[TranslateModule]
})
export class AiAdvantageComponent implements OnInit{
  currentLang = 'tr'
  isAuth = false
  auth:any
  
  constructor(
    private localStorageService:LocalStorageService,
    private router:Router,
    private translate: TranslateService,
    
    ) {}

  ngOnInit(): void {
    this.translate.currentLang
    this.isAuth = JSON.parse(this.localStorageService.getItem('auth') + '');
    this.auth = JSON.parse(this.localStorageService.getItem('auth') + '');
    
  }

  navigateRouter(url:string): void {
    this.router.navigate([this.translate.currentLang + '/' + url])
  }

  
}
